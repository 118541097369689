<template>
  <b-modal v-model="modalVisible" :no-close-on-backdrop="true" id="modal-center" centered title="Center modal"
    title-class="font-18" hide-header hide-footer>
    <h5 style="margin-top: 20px; margin-bottom: 20px; text-align: center;">{{ title }}</h5>
    <div class="progress" v-if="progress > 0">
      <div class="progress-bar c2play-primary" role="progressbar" :style="{ width: progress + '%' }"
        :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%</div>
    </div>
    <div style="width: 100%; text-align: center; margin-top: 30px; margin-bottom: 20px;">
      <i class="bx bx-loader bx-spin font-size-80 align-middle me-2"
        style="align-items: center; color: var(--primary-color);"></i>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "LoaderProcess",
  props: {
    title: String,
    progress: Number,
    visible: Boolean // Utilisez la prop "visible" plutôt que "visibles"
  },
  data() {
    return {
      modalVisible: this.visible // Utilisez "this.visible" au lieu de "this.visibles"
    }
  },
  watch: {
    visible(value) { // Changez "visibles" en "visible"
      this.modalVisible = value
    }
  }
}
</script>

<style></style>
