<template>
  <div>
    <button class="btn addButton m-1">
      <span style="color: #fff"
        ><i class="fas fa-plus font-size-18"></i> {{ text }}</span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: "addButton",
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.addButton {
  background-color: #07693a;
  color: white;
}
</style>
