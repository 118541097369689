<template>
  <div class="col-lg-7 col-xl-3">
    <!-- Simple card -->
    <b-card style="border-radius: 13px">
      <router-link
        :to="'/projects/overviewCategorie/' + categorie.id"
        style="color: #303030"
      >
        <div>
          <img
            class="card-img"
            :src="categorie.imageUrl"
            alt="Card image"
            style="object-fit: cover; opacity: 0.9"
          />

          <h4
            style="
              position: absolute;
              top: 194px;
              left: 30px;
              color: #fff;
              z-index: 11;
            "
          ></h4>
        </div>

        <b-card-title>
          <h5
            class="card-title"
            style="
              font-size: 1.4em;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              margin-bottom: 20px;
            "
          >
            {{ categorie.name }}
          </h5>
        </b-card-title>
        <b-card-text
          style="
            font-size: 1.2em;
            min-height: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 20px;
          "
        >
          <div>
            {{ categorie.description }}
          </div>
        </b-card-text>
      </router-link>
      <div style="display: flex; justify-content: end">
        <div>
          <router-link :to="'/projects/overviewCategorie/' + categorie.id">
            <span style="color: #0dcaf0; cursor: pointer; font-size: 1.8rem"
              ><i class="mdi mdi-information"></i></span
          ></router-link>
          <span
            @click="showUpdateModal()"
            style="color: #ffc107; cursor: pointer; font-size: 1.8rem"
            ><i class="mdi mdi-pencil-circle"></i
          ></span>
          <span
            @click="confirm()"
            style="color: #dc3545; cursor: pointer; font-size: 1.8rem"
            ><i class="mdi mdi-delete-circle"></i
          ></span>
        </div>
      </div>
    </b-card>
  </div>

  <b-modal
    v-model="updateModal"
    id="modal-ml"
    size="xl"
    title="Modifier la catégorie"
    title-class="font-18"
    hide-footer
  >
    <div class="card-body">
      <form>
        <div class="form-group row mb-4">
          <label for="projectname" class="col-form-label col-lg-2">Titre</label>
          <div class="col-lg-10">
            <input
              id="projectname"
              name="projectname"
              type="text"
              class="form-control"
              v-model="categ.titre"
              placeholder="Entrez lz titre de la catégorie"
            />
          </div>
        </div>

        <div class="form-group row mb-4">
          <label for="projectbudget" class="col-form-label col-lg-2"
            >Image</label
          >
          <div class="col-lg-10">
            <input
              id="projectbudget"
              name="projectbudget"
              type="file"
              @change="imageCategorie($event)"
              class="form-control"
              accept=".png, .jpeg, .jpg"
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="projectdesc" class="col-form-label col-lg-2"
            >Description</label
          >
          <div class="col-lg-10">
            <textarea
              id="projectdesc"
              class="form-control"
              rows="3"
              v-model="categ.description"
              placeholder="Entrez la description"
            ></textarea>
          </div>
        </div>
      </form>
      <br />
      <div class="row">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <addButton :text="'Modifier'" @click="updateCategorie"></addButton>
        </div>
      </div>
    </div>
  </b-modal>
  <loaderProgress :visible="process" :progress="progress" :title="titre" />
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../helpers";
import { Erreur } from "../../helpers/error";
import addButton from "./addButton.vue";
import loaderProgress from "./loaderProcess.vue";
export default {
  components: {
    addButton,
    loaderProgress,
  },
  data() {
    return {
      updateModal: false,
      process: false,
      progress: 0,
      titre: "",
      categ: {
        titre: "",
        description: "",
        image: "",
      },
      imageCat: new File([], "test"),
    };
  },
  props: {
    categorie: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.categ.titre = this.categorie.name;
    this.categ.description = this.categorie.description;
  },
  methods: {
    showUpdateModal() {
      this.updateModal = true;
    },

    confirm() {
      Swal.fire({
        title: "Voulez vraiment supprimer cette catégorie",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteCategorie();
        }
      });
    },
    deleteCategorie() {
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Suppréssion de la catégorie";
      Api.delete("/streamvodv2/rest/v2/category/delete/" + this.categorie.id, {
        onUploadProgress,
      })
        .then(() => {
          this.process = false;
          Swal.fire("Deleted!", "Catégorie supprimée avec succes", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    imageCategorie(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.imageCat = renamedFile;
      }

      console.log(this.imageCat);
    },
    updateCategorie() {
      this.process = true; // Set the process variable to true
      this.updateModal = false; // Hide the modal
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Modification de la catégorie";
      Api.putFormData(
        `/streamvodv2/rest/v2/category/update/${this.categorie.id}`,
        {
          name: this.categ.titre,
          description: this.categ.description,
          file: this.imageCat,
        },
        { onUploadProgress }
      )
        .then(function () {
          Swal.fire("Update!", "Catégorie modifiée avec succes", "success");
          location.reload();
          this.process = false;
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
