import Swal from "sweetalert2"
import router from '../router/index'

export const Erreur = {
  gestionErreur(message){
    if(message == "Request failed with status code 404"){
      Swal.fire("Erreur!", "Chemin introuvable!", "error");
    }

    if(message == "Request failed with status code 500"){
      Swal.fire("Erreur!", "Cette page a des difficultés a charger!", "error");
    }

    if(message == "Request failed with status code 502"){
      Swal.fire("Erreur!", "Cette page a des difficultés a charger!", "error");
    }

    if(message == "Request failed with status code 401"){
      Swal.fire("Attention!", "Votre session a expirée!", "info");
      localStorage.removeItem('jwtToken')
      localStorage.removeItem('user')
      router.push('/login');
    }

    if(message == "Request failed with status code 400"){
      Swal.fire("Erreur!", "Mauvaise requête!", "error");
    }

    if(message == "Network Error"){
      Swal.fire("Attention", "Vérifier votre connexion internet!", "warning");
    }

    if(message == "Request failed with status code 405"){
      Swal.fire("Erreur!", "Mauvaise méthode!", "error");
    }

    if(message == "Request failed with status code 413"){
      Swal.fire("Erreur!", "Désolé votre requete est trop longue!", "error");
    }
  }
}